<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between"
             v-if="currentTabIndex!==6">
          <div class="flex">
            <template v-if="currentTabIndex===0">
              <Select v-model="selectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="name"
                            placeholder="姓名"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="personTreeArr"
                            search
                            @onSelectChange="onChangeName">
              </MyTreeSelect>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onSelectDepartment">
              </MyTreeSelect>
              <DatePicker :value="date"
                          type="daterange"
                          :start-date="new Date()"
                          :clearable="false"
                          :editable="false"
                          placeholder="请选择时间范围"
                          :options="dateOptions"
                          @on-change="onChangeDate"
                          style="width:260px"
                          v-if="selectType===1"></DatePicker>
              <Monthrange :itemStyle="{width:'160px','margin-right':'10px'}"
                          :dateMonthrange="dateMonthrange"
                          @onChange="onChangeMonthrange"
                          v-show="selectType===2"></Monthrange>
            </template>
          </div>
          <div class="flex">
            <template v-if="selectType===1">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/attendanceReport/query'])"
                      :ghost="buttonIndex == 2 ? false : true"
                      @click.stop="onClickNearlyThreeDay">近三天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/attendanceReport/query'])"
                      :ghost="buttonIndex == 0 ? false : true"
                      @click.stop="onClickNearlySevenDay">近七天</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/attendanceReport/query'])"
                      :ghost="buttonIndex == 1 ? false : true"
                      @click.stop="onClickCurrentMonth">本月</Button>
            </template>
            <template v-if="selectType===2">
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/attendanceReport/query'])"
                      :ghost="buttonIndex == 3 ? false : true"
                      @click.stop="onClickPreMonthDay(3)">近三月</Button>
              <Button type="primary"
                      class="m-l-10"
                      v-if="checkPermi(['/admin/attendanceReport/query'])"
                      :ghost="buttonIndex == 4 ? false : true"
                      @click.stop="onClickPreMonthDay(6)">近六月</Button>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/attendanceReport/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/attendanceReport/query'])"
                    @click.stop="onResetSearchData">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div class="section">
          <Tabs v-model="currentPublicToiletEchartsTabIndex"
                class="tabs"
                key="childEcharts1">
            <TabPane v-for="item in publicToiletEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts ref="publicToiletRef"
                   height="400px"
                   :options="totalOptions"
                   :data="publicToiletEchartsData"
                   v-show="publicToiletEchartsData.length"></Echarts>
          <div class="noData"
               v-show="publicToiletEchartsData.length===0">
            <img src="@/assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
      </template>
      <div class="section">
        <template v-if="currentTabIndex===0">
          <Tabs v-model="currentPublicToiletTabIndex"
                class="tabs"
                key="child1">
            <TabPane v-for="item in PublicToiletTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentPublicToiletTabIndex == 0"></TabPane>
          </Tabs>
        </template>
        <div class="table">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="getData"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { getToday, getYesterday, getNearlyThreeDay, getCurrentMonth, getLastMonth, getYearOrMonthForMonth, getPreMonthDay, getCurrentWeek, formatDate, getNearlySevenDay, getCurrentYearToNow } from '@/utils/dateRangUtil'
import Monthrange from '@/components/common/Monthrange'
import { checkPermi } from '@/utils/permission'
export default {
  components: { Echarts, MyTreeSelect, Monthrange },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      currentTabIndex: 0,
      tabArr: [
        {
          name: '人员考勤'
        },
      ],
      carNumber: '',
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      nameId: '',
      name: '',
      departmentId: '',
      departmentName: '',
      date: [],
      dateMonthrange: [],
      currentPublicToiletEchartsTabIndex: 0,
      publicToiletEchartsTabArr: [
        {
          name: '考勤分析'
        },
      ],
      currentPublicToiletTabIndex: 0,
      PublicToiletTabArr: [
        {
          name: '考勤汇总 '
        },
        {
          name: '考勤明细'
        },
      ],
      totalOptions: {
        legend: {},
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255,255,255,0.8)',
          borderWidth: '1',
          borderColor: 'gray',
          textStyle: {
            color: 'black'
          },
          formatter: '',
        },
        dataset: {
          source: []
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{
          type: 'bar',
          barMaxWidth: 40,
          itemStyle: {
            color: "#4F86EC"
          }
        }, {
          type: 'bar',
          barMaxWidth: 40,
          itemStyle: {
            color: "#67BAC3"
          }
        }, {
          type: 'bar',
          barMaxWidth: 40,
          itemStyle: {
            color: "#57A45C"
          }
        }, {
          type: 'bar',
          barMaxWidth: 40,
          itemStyle: {
            color: "#F2BC43"
          }
        }, {
          type: 'bar',
          barMaxWidth: 40,
          itemStyle: {
            color: "#EE742F"
          }
        }]
      },
      publicToiletEchartsData: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      publicToiletAllData: [],
      columnsPublicToiletAll: [
        {
          title: '序号',
          width: 45,
          fixed: 'left',
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'employeeName',
          width: 70,
          fixed: 'left',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAttendancePersonName(row)
                }
              }
            }, row.employeeName);
          }
        },
        {
          title: '所属机构',
          key: 'deptName',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '电子围栏',
          key: 'gridName',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划考勤天数',
          key: 'planDays',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划考勤次数',
          key: 'planNumbers',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '正常',
          key: 'normality',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '迟到',
          key: 'laterIn',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '早退',
          key: 'leaveEarly',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '旷工',
          key: 'noWork',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
        {
          title: '缺卡',
          key: 'lackCard',
          fixed: 'left',
          tooltip: true,
          align: 'center'
        },
      ],
      publicToiletRefuelData: [],
      columnsPublicToiletRefuel: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'employeeName',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '日期',
          key: 'punchCardDate',
          tooltip: true,
          align: 'center'
        },
      ],
      selectArr: [],
      buttonIndex: null,
      todayArr: []
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickSearch()
    },
    currentPublicToiletTabIndex (newVal) {
      if (newVal == 0) {
        this.name = ''
        this.nameId = ''
      }
      this.onClickSearch()
    },
    selectType (newVal) {
      if (newVal == 1) {
        this.onClickCurrentMonth()
      } else {
        this.onClickCurrentYear()
      }
    }
  },
  computed: {
    ...mapGetters({
      'personTreeArr': 'getPersonTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              columns = this.columnsPublicToiletAll
              break;
            case 1:
              columns = this.columnsPublicToiletRefuel
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              data = this.publicToiletAllData
              break;
            case 1:
              data = this.publicToiletRefuelData
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updatePersonTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updatePersonTreeList()
      this.updateDepartmentTreeList()
      this.todayArr = getToday().split('-')
      if (this.todayArr[2] == '01' || this.todayArr[2] == '02' || this.todayArr[2] == '03') {
        this.onClickNearlyThreeDay()
      } else {
        this.onClickCurrentMonth()
      }
    },
    // 选择人员
    onChangeName (section) {
      if (section.type === 'user') {
        this.nameId = section.id
        this.name = section.name
      }
    },
    // 修改所属机构
    onSelectDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 日期选择器回调
    onChangeDate (date) {
      let millisecond = 1000 * 60 * 60 * 24
      let startDate = new Date(date[0]).getTime()
      let endDate = new Date(date[1]).getTime()
      if (endDate - startDate < millisecond * 2) {
        this.date = getCurrentMonth(false)
        this.$Message.info('最少选择三天')
      } else {
        this.date = date
      }
      this.buttonIndex = null
    },
    // 月份回调
    onChangeMonthrange (arr) {
      let startMonthArr = arr.month[0].split('-')
      let endMonthArr = arr.month[1].split('-')
      if (parseInt(endMonthArr[1]) - parseInt(startMonthArr[1]) >= 2) {
        this.date = arr.day
      } else {
        this.date = []
        this.dateMonthrange = []
        this.$Message.info('最少选择三个月')
      }
      this.buttonIndex = null
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay(false)
      this.getList()
      this.buttonIndex = 2
    },
    // 近七天
    onClickNearlySevenDay () {
      this.date = getNearlySevenDay(false)
      this.getList()
      this.buttonIndex = 0
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth(false)
      this.getList()
      this.buttonIndex = 1
    },
    // 近几个月
    onClickPreMonthDay (num) {
      this.date = getPreMonthDay(num)
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
      switch (num) {
        case 3:
          this.buttonIndex = 3
          break;
        case 6:
          this.buttonIndex = 4
          break;
        default:
          break;
      }
    },
    // 本年
    onClickCurrentYear () {
      this.date = getCurrentYearToNow()
      let startMonth = this.date[0].split('-')
      let endMonth = this.date[1].split('-')
      this.dateMonthrange = [startMonth[0] + '-' + startMonth[1], endMonth[0] + '-' + endMonth[1]]
      this.getList()
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          this.getPublicToiletEcharts()
          switch (this.currentPublicToiletTabIndex) {
            case 0:
              this.getAttendanceSummary()
              break;
            case 1:
              this.getPublicToiletDetail()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    getPublicToiletEcharts () {
      let params = {
        departmentId: this.departmentId,    // 机构id
      }
      // 按月查询
      // 0天 1月
      if (this.selectType == 2) {
        params.type = 1
      } else {
        params.type = 0
      }
      this.dateFormat(params)
      this.$http.getRoadPlanStatisticsEcharts(params).then((res) => {
        if (res.code == 200) {
          let data = res.result
          this.totalOptions.tooltip.formatter = e => {
            return `
              <div >
                ${e[0].data[0]}<br />
                <span style="color:#4F86EC">正常: </span> ${e[0].data[1]}<br />
                <span style="color:#67BAC3">迟到: </span>${e[0].data[2]}<br />
                <span style="color:#57A45C">早退: </span>${e[0].data[3]}<br />
                <span style="color:#F2BC43">旷工: </span>${e[0].data[4]}<br />
                <span style="color:#EE742F">缺卡: </span>${e[0].data[5]}<br />
              </div>
            `
          }
          this.publicToiletEchartsData = data
        }
      })
    },
    // 获取考勤汇总列表
    getAttendanceSummary () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        employeeId: this.nameId,
        departmentId: this.departmentId,    // 机构id
      }
      this.dateFormat(params)
      this.tableLoading = true
      this.$http.getPersonAttendance(params).then((res) => {
        if (res.code == 200) {
          this.publicToiletAllData = res.result.list
          this.totalPage = res.result.total
          this.tableLoading = false
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    getPublicToiletDetail () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        employeeId: this.nameId,
        departmentId: this.departmentId,    // 机构id
      }
      this.dateFormat(params)
      this.tableLoading = true
      this.$http.getPersonAttendanceDetail(params).then((res) => {
        this.tableLoading = false
        if (res.code == 200) {
          let maxIndex = 0
          res.result.list.forEach((item, index) => {
            if (item.attendanceDetailDayVoList.length > maxIndex) {
              maxIndex = index
            }
          })
          this.dateDetailColumnInit(res.result.list[maxIndex].attendanceDetailDayVoList)
          this.totalPage = res.result.total
          this.publicToiletRefuelData = res.result.list
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 点击名称
    onClickAttendancePersonName (row) {
      this.nameId = row.employeeId
      this.name = row.employeeName
      this.currentPublicToiletTabIndex = 1
    },
    // 初始化table 上下班列
    dateDetailColumnInit (arr) {
      let dateArr = []
      arr.forEach((item, index) => {
        let title
        let key
        for (const items in item) {
          if (items == 'gridName') {
            title = '电子围栏'
            key = 'gridName'
          } else if (items == 'workTime') {
            title = '上班时间'
            key = 'workTime'
          } else if (items == 'afterWorkTime') {
            title = '下班时间'
            key = 'afterWorkTime'
          }
          if (items != 'id' && items != 'gridId') {
            dateArr.push({
              title: title,
              key: key,
              align: 'center',
              render: (h, params) => {
                let row = params.row
                let column = params.column
                let content
                if (row.attendanceDetailDayVoList[index]) {
                  return h('div', {
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                  }, row.attendanceDetailDayVoList[index][items]);
                } else {
                  if (items != 'gridName') {
                    return h('div', {
                      style: {
                        color: '#A8A8A8'
                      }
                    }, '无考勤计划');
                  } else {
                    return h('div', '');
                  }
                }
              }
            })
          }
        }
      })
      this.columnsPublicToiletRefuel.splice(4, this.columnsPublicToiletRefuel.length - 4, ...dateArr)
    },
    // 统一格式化开始日期 结束日期
    dateFormat (params) {
      if (this.date[0]) {
        params.startDate = this.date[0]
        params.endDate = this.date[1]
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.pageSize = 20
      this.getList()
    },
    // 重置按钮
    onResetSearchData () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置数据
    resetSearchData () {
      this.selectType = 1
      this.departmentId = ''
      this.departmentName = ''
      this.nameId = ''
      this.name = ''
      this.date = []
      this.dateMonthrange = []
      this.buttonIndex = null
      switch (this.currentTabIndex) {
        case 0:
          this.currentPublicToiletEchartsTabIndex = 0
          this.currentPublicToiletTabIndex = 0
          break;
        default:
          break;
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
.noData {
  height: 400px;
  font-size: 16px;
  img {
    width: 150px;
    height: 100px;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }
  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;
      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;
        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }
      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>